import { DivExtensionContext } from '@divkitframework/divkit/typings/common';
import { Variable } from '@divkitframework/divkit/typings/variables';
import { DivExtension, IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';
import { getRandomArbitrary } from '../../../helpers/getRandomArbitrary';
import { safeGetFromLocalStorage, safeSetToLocalStorage } from '../../../helpers/localStorage';

const VARIABLE_NAME = 'countUp';

class CountUpExtension implements DivExtension {
  // Since there is can be several instances of the timer we need to run only one
  private static timerStarted = false;

  async mountView(node: HTMLElement, context: DivExtensionContext) {
    const variable = context.variables.get(VARIABLE_NAME);

    const storeValue = safeGetFromLocalStorage(VARIABLE_NAME);

    if (storeValue) {
      variable?.setValue(JSON.parse(storeValue));
    }

    if (variable && !CountUpExtension.timerStarted) {
      CountUpExtension.timerStarted = true;
      this.countUpTimer(variable);
    }
  }

  private countUpTimer(variable: Variable) {
    const randomInterval = getRandomArbitrary(5, 10);

    setTimeout(() => {
      const countUpValue = variable.getValue();
      let value = Number(this.joinArray(countUpValue as { v: number }[]));
      value += getRandomArbitrary(3, 8);
      const setValue = this.splitString(String(value));
      safeSetToLocalStorage(VARIABLE_NAME, JSON.stringify(setValue));

      variable.setValue(setValue);
      this.countUpTimer(variable);
    }, randomInterval * 1000);
  }

  private joinArray(arr: { v: number }[]): string {
    return arr.map((item) => String(item.v)).join('');
  }

  private splitString(str: string): { v: string }[] {
    return str.split('').map((char) => ({ v: String(char) }));
  }
}

export default class CountUpExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.COUNT_UP;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = CountUpExtension;
  }
}

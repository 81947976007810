import React, { ComponentType, useEffect } from 'react';

const POPSTATE_EVENT = 'popstate';

const withPreventBackNavigation = <P extends object>(
  Component: ComponentType<P>,
  onBack: () => void,
  turnedOn: boolean
) => {
  return (props: P) => {
    const onBackNavigation = (event: PopStateEvent) => {
      if (turnedOn) {
        event.preventDefault();
        onBack();
      }
    };

    useEffect(() => {
      if (turnedOn) {
        window.history.pushState(null, '', window.location.href);
        window.addEventListener(POPSTATE_EVENT, onBackNavigation);
      }

      return () => {
        window.removeEventListener(POPSTATE_EVENT, onBackNavigation);
      };
    }, []);

    return <Component {...props} />;
  };
};

export default withPreventBackNavigation;

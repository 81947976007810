export enum COMMON_COLORS {
  BLACK = '#000',
  WHITE = '#FFF',

  INFO_MAIN = '#22BFF1',
  WARNING_MAIN = '#FECE48',
  SUCCESS_MAIN = '#0DC268',
  ERROR_MAIN = '#FF575C',
}

export enum GREY_THEME_COLORS {
  TEXT_PRIMARY = '#000000',
  TEXT_SECONDARY = '#8A8A8F',
  TEXT_DISABLED = '#CDCED2',

  BACKGROUND_DEFAULT = '#FAFAFA',
  BACKGROUND_PAPER = '#FFFFFF',

  BACKDROP = 'rgba(0, 0, 0, 0.5)',

  // Primary
  PRIMARY_FIT = '#FF7F6B',
  PRIMARY_FIT_DARK = '#F16B55',
  PRIMARY_FIT_LIGHT = '#FFE5E1',

  PRIMARY_DANCE = '#9550F2',
  PRIMARY_DANCE_DARK = '#7740C2',
  PRIMARY_DANCE_LIGHT = '#EFE5FD',

  PRIMARY_WALK = '#5F64C4',
  PRIMARY_WALK_DARK = '#A0A4F8',
  PRIMARY_WALK_LIGHT = '#EBECFE',

  PRIMARY_MAN = '#4151E0',
  PRIMARY_MAN_DARK = '#3441B3',
  PRIMARY_MAN_LIGHT = '#C0ECFF',

  PRIMARY_KETO = '#FF7F6B',
  PRIMARY_KETO_DARK = '#F16B55',
  PRIMARY_KETO_LIGHT = '#FFE5E1',

  PRIMARY_FASTING = '#01C1AC',
  PRIMARY_FASTING_DARK = '#019A8A',
  PRIMARY_FASTING_LIGHT = '#DFF9F6',

  PRIMARY_VEGAN = '#00715D',
  PRIMARY_VEGAN_DARK = '#004438',
  PRIMARY_VEGAN_LIGHT = '#E9F7F5',

  // Secondary
  SECONDARY_FIT = '#01C1AC',
  SECONDARY_FIT_LIGHT = '#CCF3EE',
  SECONDARY_FIT_DARK = '#019A8A',

  SECONDARY_DANCE = '#F73C74',
  SECONDARY_DANCE_LIGHT = '#FDD8E3',
  SECONDARY_DANCE_DARK = '#C6305D',

  SECONDARY_WALK = '#78E17C',
  SECONDARY_WALK_LIGHT = '#E4F9E5',
  SECONDARY_WALK_DARK = '#60B463',

  SECONDARY_MAN = '#45D1D1',
  SECONDARY_MAN_LIGHT = '#DAF6F6',
  SECONDARY_MAN_DARK = '#37A7A7',

  SECONDARY_KETO = '#FFA06D',
  SECONDARY_KETO_LIGHT = '#FFEBE0',
  SECONDARY_KETO_DARK = '#E59165',

  SECONDARY_FASTING = '#FFC359',
  SECONDARY_FASTING_LIGHT = '#FFEDCD',
  SECONDARY_FASTING_DARK = '#ECA527',

  SECONDARY_VEGAN = '#A8CF69',
  SECONDARY_VEGAN_LIGHT = '#EAF4D9',
  SECONDARY_VEGAN_DARK = '#8EA961',

  GREY_50 = '#FAFAFA',
  GREY_100 = '#F4F4F4',
  GREY_200 = '#EEEEEE',
  GREY_300 = '#E0E0E0',
  GREY_400 = '#CDCED2',
  GREY_500 = '#8A8A8E',
  GREY_600 = '#727277',
  GREY_700 = '#606064',
  GREY_800 = '#3A3A3C',
  GREY_900 = '#1F1F1F',

  SPECIAL_OFFER_FIT_GRADIENT = 'linear-gradient(348.65deg, #54E0CF 8.36%, #00B4A0 91.64%)',
  SPECIAL_OFFER_DANCE_GRADIENT = 'linear-gradient(168.86deg, #A54EDF 6.8%, #F73C74 90.25%)',
  SPECIAL_OFFER_WALK_GRADIENT = 'linear-gradient(348.65deg, #78E17C 8.36%, #54BF59 91.64%)',
  SPECIAL_OFFER_MAN_GRADIENT = 'linear-gradient(180deg, #4151E0 0%, #12E3FF 100%)',
  SPECIAL_OFFER_KETO_GRADIENT = 'linear-gradient(180deg, #E05842 0%, #FFF1CD 100%)',
  SPECIAL_OFFER_FASTING_GRADIENT = 'linear-gradient(180deg, #F2B20B 0%, #FCCE7C 100%)',
  SPECIAL_OFFER_VEGAN_GRADIENT = 'linear-gradient(180deg, #86BC2D 0%, #B8DA81 100%)',

  SHADOW = '0 10px 30px rgba(17, 21, 32, 0.09)',
}

export enum DARK_THEME_COLORS {
  TEXT_PRIMARY = '#FFFFFF',
  TEXT_SECONDARY = '#727277',
  TEXT_DISABLED = '#3A3A3C',

  BACKGROUND_DEFAULT = '#000000',
  BACKGROUND_PAPER = '#1F1F1F',

  BACKDROP = 'rgba(154, 154, 154, 0.2)',

  // Primary
  PRIMARY_FIT = '#FF7F6B',
  PRIMARY_FIT_DARK = '#F16B55',
  PRIMARY_FIT_LIGHT = '#4D2620',

  PRIMARY_DANCE = '#9550F2',
  PRIMARY_DANCE_DARK = '#7740C2',
  PRIMARY_DANCE_LIGHT = '#4B2879',

  PRIMARY_WALK = '#5F64C4',
  PRIMARY_WALK_DARK = '#A0A4F8',
  PRIMARY_WALK_LIGHT = '#24264A',

  PRIMARY_MAN = '#4151E0',
  PRIMARY_MAN_DARK = '#3441B3',
  PRIMARY_MAN_LIGHT = '#212970',

  PRIMARY_KETO = '#FF7F6B',
  PRIMARY_KETO_DARK = '#F16B55',
  PRIMARY_KETO_LIGHT = '#4D2620',

  PRIMARY_FASTING = '#01C1AC',
  PRIMARY_FASTING_DARK = '#019A8A',
  PRIMARY_FASTING_LIGHT = '#003A34',

  PRIMARY_VEGAN = '#00715D',
  PRIMARY_VEGAN_DARK = '#004438',
  PRIMARY_VEGAN_LIGHT = '#003A34',

  // Secondary
  SECONDARY_FIT = '#01C1AC',
  SECONDARY_FIT_LIGHT = '#003A34',
  SECONDARY_FIT_DARK = '#019A8A',

  SECONDARY_DANCE = '#F73C74',
  SECONDARY_DANCE_LIGHT = '#4A1223',
  SECONDARY_DANCE_DARK = '#C6305D',

  SECONDARY_WALK = '#78E17C',
  SECONDARY_WALK_LIGHT = '#244425',
  SECONDARY_WALK_DARK = '#60B463',

  SECONDARY_MAN = '#45D1D1',
  SECONDARY_MAN_LIGHT = '#153F3F',
  SECONDARY_MAN_DARK = '#37A7A7',

  SECONDARY_KETO = '#FFA06D',
  SECONDARY_KETO_LIGHT = '#894D2D',
  SECONDARY_KETO_DARK = '#E59165',

  SECONDARY_FASTING = '#FFC359',
  SECONDARY_FASTING_LIGHT = '#AC6E00',
  SECONDARY_FASTING_DARK = '#ECA527',

  SECONDARY_VEGAN = '#A8CF69',
  SECONDARY_VEGAN_LIGHT = '#546834',
  SECONDARY_VEGAN_DARK = '#8EA961',

  GREY_50 = '#FAFAFA',
  GREY_100 = '#1F1F1F',
  GREY_200 = '#1F1F1F',
  GREY_300 = '#3A3A3C',
  GREY_400 = '#3A3A3C',
  GREY_500 = '#727277',
  GREY_600 = '#727277',
  GREY_700 = '#606064',
  GREY_800 = '#3A3A3C',
  GREY_900 = '#1F1F1F',

  SPECIAL_OFFER_FIT_GRADIENT = 'linear-gradient(348.65deg, #54E0CF 8.36%, #00B4A0 91.64%)',
  SPECIAL_OFFER_DANCE_GRADIENT = 'linear-gradient(168.86deg, #A54EDF 6.8%, #F73C74 90.25%)',
  SPECIAL_OFFER_WALK_GRADIENT = 'linear-gradient(348.65deg, #78E17C 8.36%, #54BF59 91.64%)',
  SPECIAL_OFFER_MAN_GRADIENT = 'linear-gradient(180deg, #4151E0 0%, #12E3FF 100%)',
  SPECIAL_OFFER_KETO_GRADIENT = 'linear-gradient(180deg, #E05842 0%, #FFF1CD 100%)',
  SPECIAL_OFFER_FASTING_GRADIENT = 'linear-gradient(180deg, #F2B20B 0%, #FCCE7C 100%)',
  SPECIAL_OFFER_VEGAN_GRADIENT = 'linear-gradient(180deg, #86BC2D 0%, #B8DA81 100%)',

  SHADOW = 'none',
}

export enum LIGHT_THEME_COLORS {
  TEXT_PRIMARY = '#000000',
  TEXT_SECONDARY = '#8A8A8F',
  TEXT_DISABLED = '#CDCED2',

  BACKGROUND_DEFAULT = '#FFFFFF',
  BACKGROUND_PAPER = '#FFFFFF',

  BACKDROP = 'rgba(0, 0, 0, 0.5)',

  // Primary
  PRIMARY_FIT = '#FF575C',
  PRIMARY_FIT_DARK = '#F16B55',
  PRIMARY_FIT_LIGHT = '#FFE5E1',

  PRIMARY_DANCE = '#9550F2',
  PRIMARY_DANCE_DARK = '#7740C2',
  PRIMARY_DANCE_LIGHT = '#EFE5FD',

  PRIMARY_WALK = '#5F64C4',
  PRIMARY_WALK_DARK = '#A0A4F8',
  PRIMARY_WALK_LIGHT = '#EBECFE',

  PRIMARY_MAN = '#4151E0',
  PRIMARY_MAN_DARK = '#3441B3',
  PRIMARY_MAN_LIGHT = '#C0ECFF',

  PRIMARY_KETO = '#FF7F6B',
  PRIMARY_KETO_DARK = '#F16B55',
  PRIMARY_KETO_LIGHT = '#FFE5E1',

  PRIMARY_FASTING = '#01C1AC',
  PRIMARY_FASTING_DARK = '#019A8A',
  PRIMARY_FASTING_LIGHT = '#DFF9F6',

  PRIMARY_VEGAN = '#00715D',
  PRIMARY_VEGAN_DARK = '#004438',
  PRIMARY_VEGAN_LIGHT = '#E9F7F5',

  // Secondary
  SECONDARY_FIT = '#01C1AC',
  SECONDARY_FIT_LIGHT = '#CCF3EE',
  SECONDARY_FIT_DARK = '#019A8A',

  SECONDARY_DANCE = '#F73C74',
  SECONDARY_DANCE_LIGHT = '#FDD8E3',
  SECONDARY_DANCE_DARK = '#C6305D',

  SECONDARY_WALK = '#78E17C',
  SECONDARY_WALK_LIGHT = '#E4F9E5',
  SECONDARY_WALK_DARK = '#60B463',

  SECONDARY_MAN = '#45D1D1',
  SECONDARY_MAN_LIGHT = '#DAF6F6',
  SECONDARY_MAN_DARK = '#37A7A7',

  SECONDARY_KETO = '#FFA06D',
  SECONDARY_KETO_LIGHT = '#FFEBE0',
  SECONDARY_KETO_DARK = '#E59165',

  SECONDARY_FASTING = '#FFC359',
  SECONDARY_FASTING_LIGHT = '#FFEDCD',
  SECONDARY_FASTING_DARK = '#ECA527',

  SECONDARY_VEGAN = '#A8CF69',
  SECONDARY_VEGAN_LIGHT = '#EAF4D9',
  SECONDARY_VEGAN_DARK = '#8EA961',

  GREY_50 = '#FAFAFA',
  GREY_100 = '#F4F4F4',
  GREY_200 = '#EEEEEE',
  GREY_300 = '#E0E0E0',
  GREY_400 = '#CDCED2',
  GREY_500 = '#8A8A8E',
  GREY_600 = '#727277',
  GREY_700 = '#606064',
  GREY_800 = '#3A3A3C',
  GREY_900 = '#1F1F1F',

  SPECIAL_OFFER_FIT_GRADIENT = 'linear-gradient(348.65deg, #54E0CF 8.36%, #00B4A0 91.64%)',
  SPECIAL_OFFER_DANCE_GRADIENT = 'linear-gradient(168.86deg, #A54EDF 6.8%, #F73C74 90.25%)',
  SPECIAL_OFFER_WALK_GRADIENT = 'linear-gradient(348.65deg, #78E17C 8.36%, #54BF59 91.64%)',
  SPECIAL_OFFER_MAN_GRADIENT = 'linear-gradient(180deg, #4151E0 0%, #12E3FF 100%)',
  SPECIAL_OFFER_KETO_GRADIENT = 'linear-gradient(180deg, #E05842 0%, #FFF1CD 100%)',
  SPECIAL_OFFER_FASTING_GRADIENT = 'linear-gradient(180deg, #F2B20B 0%, #FCCE7C 100%)',
  SPECIAL_OFFER_VEGAN_GRADIENT = 'linear-gradient(180deg, #86BC2D 0%, #B8DA81 100%)',

  SHADOW = '0 10px 30px rgba(17, 21, 32, 0.09)',
}

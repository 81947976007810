import type { VariableValue } from '@divkitframework/divkit/typings/variables';
import type { VariableType } from './types';

export enum GLOBAL_VARIABLES_NAMES {
  USER_DATA = 'userData',
  PAYWALL_DATA = 'paywallData',
  COUNT_DOWN = 'countDown',
}

export const GLOBAL_VARIABLES_SETTINGS: {
  [key in GLOBAL_VARIABLES_NAMES]: {
    type: VariableType;
    initial: VariableValue;
    // To map view variables
    viewMapper?: (value: VariableValue) => VariableValue;
  };
} = {
  [GLOBAL_VARIABLES_NAMES.USER_DATA]: {
    type: 'dict',
    initial: {},
  },
  [GLOBAL_VARIABLES_NAMES.PAYWALL_DATA]: {
    type: 'dict',
    initial: {},
  },
  [GLOBAL_VARIABLES_NAMES.COUNT_DOWN]: {
    type: 'string',
    initial: '96',
  },
};

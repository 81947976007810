const safeGetFromLocalStorage = (key: string): string | null => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      return window.localStorage.getItem(key);
    }
  } catch {
    console.warn('Storage is not accessible');
  }
  return null;
};

const safeSetToLocalStorage = (key: string, value: string): void => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  } catch {
    console.warn('Storage is not accessible');
  }
};

export { safeGetFromLocalStorage, safeSetToLocalStorage };

import { DivExtensionContext } from '@divkitframework/divkit/typings/common';
import { Variable } from '@divkitframework/divkit/typings/variables';
import { DivExtension, IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';
import { getRandomArbitrary } from '../../../helpers/getRandomArbitrary';
import { safeGetFromLocalStorage, safeSetToLocalStorage } from '../../../helpers/localStorage';

const VARIABLE_NAME = 'countDown';

class CountDownExtension implements DivExtension {
  // Since there is can be several instances of the timer we need to run only one
  private static timerStarted = false;

  async mountView(node: HTMLElement, context: DivExtensionContext) {
    const variable = context.variables.get(VARIABLE_NAME);

    const storeValue = safeGetFromLocalStorage(VARIABLE_NAME);

    if (storeValue) {
      variable?.setValue(storeValue);
    }

    if (variable && !CountDownExtension.timerStarted) {
      CountDownExtension.timerStarted = true;
      this.countDownTimer(variable);
    }
  }

  private countDownTimer(variable: Variable) {
    const randomInterval = getRandomArbitrary(5, 10);

    setTimeout(() => {
      let countDownValue = Number(variable.getValue());
      if (countDownValue > 5) {
        countDownValue -= getRandomArbitrary(1, 5);
        safeSetToLocalStorage(VARIABLE_NAME, String(countDownValue));
        variable.setValue(String(countDownValue));

        this.countDownTimer(variable);
      }
    }, randomInterval * 1000);
  }
}

export default class CountDownExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.COUNT_DOWN;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = CountDownExtension;
  }
}

import regExs from './regExs';

export const enum FORM_NAMES {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
  CARD_NUMBER = 'cardNumber',
  CARD_HOLDER = 'cardHolder',
  CARD_CVC = 'cardCvc',
  CARD_EXPIRY = 'cardExpiry',
  START_AT = 'startAt',
  PRICE = 'price',
  COMMENTS = 'comments',
  PAYMENT_SYSTEM = 'paymentSystem',
  VALID_DUE = 'validDue',
  COUNTRY = 'country',
  ADDRESS = 'address',
  ZIP = 'zip',
}

type Form = {
  [key in FORM_NAMES]: {
    name: string;
    label: string;
    placeholder: string;
    requiredError: string;
    validationError: string;
    cardsAcceptedError?: string;
    regEx?: RegExp;
  };
};

const FORM: Form = {
  [FORM_NAMES.NAME]: {
    name: 'name',
    label: 'name.label',
    placeholder: 'name.placeholder',
    requiredError: 'name.error.required',
    validationError: 'name.error.chars',
    regEx: regExs.lettersOnly,
  },
  [FORM_NAMES.COMMENTS]: {
    name: 'comments',
    label: 'comments.label',
    placeholder: 'comments.placeholder',
    requiredError: '',
    validationError: '',
  },
  [FORM_NAMES.EMAIL]: {
    name: 'email',
    label: 'email.label',
    placeholder: 'email.placeholder',
    requiredError: 'email.error.not_valid_email',
    validationError: 'email.error.not_valid_email',
    regEx: regExs.email,
  },
  [FORM_NAMES.PASSWORD]: {
    name: 'password',
    label: 'password.label',
    placeholder: 'password.placeholder',
    requiredError: 'password.error.required',
    validationError: 'password.error.min',
    regEx: regExs.password,
  },
  [FORM_NAMES.CARD_NUMBER]: {
    name: 'cardNumber',
    label: 'cardNumber',
    placeholder: 'card.card_number',
    requiredError: 'card.error.cardRequired',
    validationError: 'card.error.cardError',
    cardsAcceptedError: 'card.error.acceptedError',
  },
  [FORM_NAMES.CARD_HOLDER]: {
    name: 'cardHolder',
    label: 'cardHolder',
    placeholder: 'card.card_holder',
    requiredError: 'name.error.required',
    validationError: 'card.error.nameError',
    regEx: regExs.cardHolder,
  },
  [FORM_NAMES.CARD_CVC]: {
    name: 'cardCvc',
    label: 'cardCvc',
    placeholder: 'card.card_cvc',
    requiredError: 'card.error.cvcRequired',
    validationError: 'card.error.cvcError',
    regEx: regExs.cardCvc,
  },
  [FORM_NAMES.CARD_EXPIRY]: {
    name: 'cardExpiry',
    label: 'cardExpiry',
    placeholder: 'card.card_expiry',
    requiredError: 'card.error.expRequired',
    validationError: 'card.error.expError',
  },
  // subscription cancel
  [FORM_NAMES.START_AT]: {
    name: 'startAt',
    label: 'unsubscribe.v1.date',
    placeholder: '',
    requiredError: '',
    validationError: '',
  },
  [FORM_NAMES.PRICE]: {
    name: 'price',
    label: 'unsubscribe.v1.price',
    placeholder: '',
    requiredError: '',
    validationError: '',
  },
  [FORM_NAMES.PAYMENT_SYSTEM]: {
    name: 'paymentSystem',
    label: 'unsubscribe.v1.payment_system',
    placeholder: '',
    requiredError: '',
    validationError: '',
  },
  [FORM_NAMES.VALID_DUE]: {
    name: 'validDue',
    label: 'unsubscribe.v1.next_date',
    placeholder: '',
    requiredError: '',
    validationError: '',
  },
  [FORM_NAMES.COUNTRY]: {
    name: 'country',
    label: '',
    placeholder: '',
    requiredError: 'paywall.payment_form.error.country',
    validationError: '',
  },
  [FORM_NAMES.ADDRESS]: {
    name: 'address',
    label: 'paywall.payment_form.address_line_1',
    placeholder: 'paywall.payment_form.address_line_1',
    requiredError: 'paywall.payment_form.error.address_line_1',
    validationError: 'paywall.payment_form.error.country',
  },
  [FORM_NAMES.ZIP]: {
    name: 'zip',
    label: 'paywall.payment_form.zipCode',
    placeholder: 'paywall.payment_form.zipCode',
    requiredError: 'paywall.payment_form.error.zipCode',
    validationError: 'paywall.payment_form.error.zipCodeError',
    regEx: regExs.zipMinLength,
  },
};

export default FORM;

import React, { FC } from 'react';
import { BoxProps } from '@mui/material/Box/Box';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(3, 7),
  display: 'flex',
  alignItems: 'center',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(5),
}));

export interface IProps extends BoxProps {
  icon?: React.ReactNode;
}

const AlertBar: FC<IProps> = ({ children, icon, ...rest }) => {
  return (
    <Container {...rest}>
      <IconWrapper>{icon || <WarningRoundedIcon color='warning' sx={{ scale: '1.5' }} />}</IconWrapper>
      <Typography component='span' variant='body2'>
        {children}
      </Typography>
    </Container>
  );
};

export default AlertBar;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Drawer, Typography } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { Locales, LOCALES_LIST, PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import { useAnalytics } from '../../../hooks/useAnalytics';
import ChartFitEn from '../../../assets/images/special-offer-info-modal/fit-chart-en.png';
import FireIconFit from '../../../assets/icons/fit-fire.svg';
import { IPagesParams } from '../../../pages/Offer';
import AlertBar from '../../molecules/AlertBar/AlertBar';
import LoadingButton from '../../atoms/LoadingButton/LoadingButton';
import { EVENT_TYPES, EVENTS } from '../../../constants/analytics';

const IMAGES: { [key in Products.Names]: { [key in Locales.List]?: string } } = {
  [PRODUCT_NAMES.FIT]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.DANCE]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.KETO]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.FASTING]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.VEGAN]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.MAN]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
  [PRODUCT_NAMES.WALK]: {
    [LOCALES_LIST.EN]: ChartFitEn,
  },
};

const ICONS: { [key in Products.Names]: string } = {
  [PRODUCT_NAMES.FIT]: FireIconFit,
  [PRODUCT_NAMES.DANCE]: FireIconFit,
  [PRODUCT_NAMES.KETO]: FireIconFit,
  [PRODUCT_NAMES.FASTING]: FireIconFit,
  [PRODUCT_NAMES.VEGAN]: FireIconFit,
  [PRODUCT_NAMES.MAN]: FireIconFit,
  [PRODUCT_NAMES.WALK]: FireIconFit,
};

const StyledImage = styled.img`
  width: 100%;
`;

const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
}));

const TextWrapper = styled(Box)(() => ({}));

const AlertBarWrapper = styled(AlertBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '20px',
  width: 'inherit',
}));

export interface IDrawerWrappedProps extends DrawerProps {
  onClose: (e: React.MouseEvent) => void;
}

const SpecialOfferInfoDrawer = ({ onClose, open, ...rest }: IDrawerWrappedProps) => {
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const { locale, productName } = useParams<keyof IPagesParams>() as IPagesParams;

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.SPECIAL_DISCOUNT,
      properties: {},
    });
  }, []);

  return (
    <Drawer keepMounted open={open} {...rest}>
      <Typography variant='h3'>{t('paywall.special_offer_info_modal.title')}</Typography>
      <TextWrapper mt={4} mb={4}>
        <Text>
          <Trans t={t} i18nKey='paywall.special_offer_info_modal.description'>
            <strong>70%</strong> of users who started their <strong>Pilates Workout Plan</strong> advanced in their
            goals within the <strong>first month*</strong>
          </Trans>
        </Text>
      </TextWrapper>
      <Box>
        <StyledImage src={IMAGES[productName][locale] || IMAGES[productName][LOCALES_LIST.EN]} alt='chart' />
        <Typography variant='body2' align='left' color='text.secondary'>
          <Trans t={t} i18nKey='paywall.special_offer_info_modal.note_1'>
            *based on the data from users who log the progress in the app
          </Trans>
        </Typography>
      </Box>
      <AlertBarWrapper mt={4} icon={<img src={ICONS[productName]} alt='note icon' />}>
        <Trans t={t} i18nKey='paywall.special_offer_info_modal.note_2' />
      </AlertBarWrapper>
      <LoadingButton onClick={onClose} sx={{ mt: 10, mb: 2, padding: '20px 0' }}>
        {t('button.got_it_2')}
      </LoadingButton>
    </Drawer>
  );
};

export default SpecialOfferInfoDrawer;

import { useState } from 'react';

const useToggle = (initState = false): [boolean, () => void] => {
  const [state, setState] = useState<boolean>(initState);

  const toggle = () => {
    setState((p) => !p);
  };

  return [state, toggle];
};

export default useToggle;

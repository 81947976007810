import { Layouts, Payments, Paywalls } from '@organicapps/organictypes';
import BaseApi from './baseApi';
import { CustomResponse } from './customResponse';

export default class PaymentsClient {
  private baseApi: BaseApi;

  constructor(baseApi: BaseApi) {
    this.baseApi = baseApi;
  }

  async getPaywallData(popupVariant: string) {
    const response = await this.baseApi.get<Paywalls.IPaywallData>(`/v1/paywall/${popupVariant}`);
    return response.data;
  }

  async getLayoutData(id: string) {
    const response = await this.baseApi.get<Layouts.IPopup>(`/v1/paywall/layout/${id}`);
    return response.data;
  }

  async generateBraintreeClientToken() {
    const response = await this.baseApi.get<Payments.IGenerateBraintreeClientToken>('/payment/braintree/token');
    return response.data;
  }

  async onSubscriptionPay<T, R>(payload: Payments.ISubscriptionPayload<T>) {
    const response = await this.baseApi.post<T, CustomResponse<R>>('/payment/subscription', payload);
    return response.data;
  }

  async onSinglePay<T, R>(payload: Payments.ISinglePayPayload<T>) {
    const response = await this.baseApi.post<T, CustomResponse<R>>('/payment/purchase', payload);
    return response.data;
  }

  async onUpdateSubscription(appUserToken: string, payload: Payments.ISubscriptionUpdatePayload) {
    const response = await this.baseApi.patch<Payments.IPaymentDefaultResponse>(
      '/payment/update-subscription',
      payload,
      {
        headers: {
          'x-session-token': appUserToken,
        },
      }
    );
    return response.data;
  }

  async onGetPaypalSubscriptionDetails<T, R>(subscriptionId: string) {
    const response = await this.baseApi.get<T, CustomResponse<R>>(
      `/payment/paypal-details-subscription/${subscriptionId}`
    );
    return response.data;
  }
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DivKit } from '@divkitframework/react';
import { createPortal } from 'react-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Paywalls } from '@organicapps/organictypes';
import { DivJson } from '@divkitframework/divkit/typings/common';
import { useLogger } from '../../hooks/useLogger';
import { useGlobalVariables } from '../../hooks/useGlobalVariables';
import EventEmitterFactory, { ShowCardEventProcessor } from '../../modules/events';
import { useAnalytics } from '../../hooks/useAnalytics';
import type { StatCallbackType } from '../../types';
import ExtensionsProviderFactory, {
  IntercomExtensionBuilder,
  LocalizationExtensionBuilder,
  LottieExtensionBuilder,
  StickyExtensionBuilder,
  CountUpExtensionBuilder,
  CountDownExtensionBuilder,
} from '../../modules/extensions';
import ActionsProcessorFactory, {
  ActiveStateToggleActionProcessor,
  PaywallCtaClickActionProcessor,
  PaywallCtaScrollActionProcessor,
} from '../../modules/actions';
import { PAGES } from '../../constants/pages';
import { useFingerprint } from '../../hooks/useFingerprint';
import { useLayoutsStore, usePaywallsStore, useUserStore } from '../../store';
import ApiClients from '../../api';
import { useRouterWrapper } from '../../hooks/useRouterWrapper';
import OfferPaymentDrawer from '../../components/drawers/OfferPaymentDrawer/OfferPaymentDrawer';
import CustomComponentsFactory, { CUSTOM_COMPONENTS, PriceListComponent } from '../../modules/custom-components';
import { SEARCH_PARAMS } from '../../constants/searchParams';
import SpecialOfferInfoDrawer from '../../components/drawers/SpecialOfferInfoDrawer/SpecialOfferInfoDrawer';
import withPreventBackNavigation from '../../HOCs/withPreventBackNavigation';
import useToggle from '../../hooks/useToggle';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';

const D_OFFER = () => {
  const logger = useLogger();
  const globalVariables = useGlobalVariables();
  const analytics = useAnalytics();
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState<boolean>(false);

  const fingerprint = useFingerprint();
  const userClient = ApiClients.getUserClient();
  const go = useRouterWrapper();
  const [params] = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isPayed, appUserToken } = useUserStore((state) => ({
    isPayed: state.paid,
    appUserToken: state.appUserToken,
  }));
  const {
    _id,
    selectedPaywallList,
    list,
    secondaryList,
    specialOfferPaywall,
    showSpecialPrices,
    setSelectedPaywallList,
  } = usePaywallsStore((state) => ({
    _id: state._id,
    selectedPaywallList: state.selectedPaywallList,
    secondaryList: state?.secondaryList,
    list: state.list,
    specialOfferPaywall: state.settings.specialOfferPaywall,
    showSpecialPrices: state.showSpecialPrices,
    setSelectedPaywallList: state.setSelectedPaywallList,
  }));
  const { paywallLayout } = useLayoutsStore((state) => ({
    paywallLayout: state.paywallLayout,
  }));

  const [openSpecialOfferInfoDrawer, toggleSpecialOfferInfoDrawer] = useToggle(false);
  const [specialOfferInfoDrawerEnabled, toggleSpecialOfferInfoDrawerEnabled] = useToggle(!!specialOfferPaywall);

  const priceListRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (priceListRef.current) {
      priceListRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, []);

  const togglePaymentDrawer = useCallback(() => setOpenPaymentDrawer((prev) => !prev), []);

  const eventEmitter = useMemo(
    () =>
      EventEmitterFactory([new ShowCardEventProcessor({ logger, analytics })], {
        logger,
      }),
    [logger]
  );

  const extensions = useMemo(
    () =>
      ExtensionsProviderFactory(
        [
          new LottieExtensionBuilder(),
          new IntercomExtensionBuilder(),
          new LocalizationExtensionBuilder(),
          new StickyExtensionBuilder(),
          new CountUpExtensionBuilder(),
          new CountDownExtensionBuilder(),
        ],
        {
          logger,
        }
      ),
    [logger]
  );
  const components = useMemo(() => CustomComponentsFactory([new PriceListComponent()], { logger }), [logger]);

  const [container, setContainer] = useState<Element[]>();

  useEffect(() => {
    const containers = document.getElementsByTagName(CUSTOM_COMPONENTS.PRICE_LIST);
    setContainer(Array.from(containers));
  }, []);

  useEffect(() => {
    if (isPayed) {
      fingerprint
        .createFingerprint()
        .then((fingerprint) => {
          userClient.sendUserFingerprint(appUserToken, fingerprint).catch((e) => logger.logError(e as Error));
        })
        .catch((e) => logger.logError(e as Error));
      go({ routeChangedPart: { page: PAGES.SUCCESS } });
    }
  }, [isPayed, appUserToken, userClient, logger]);

  const priceListToShow = useMemo(
    () => (showSpecialPrices && secondaryList?.length ? secondaryList : list),
    [showSpecialPrices, secondaryList, list]
  );

  const initialSelectedPaywallList = useMemo(() => {
    let notSoldOut: Paywalls.IPaywallList | null = null;
    let defaultSelected: Paywalls.IPaywallList | null = null;
    if (priceListToShow) {
      const selectedPriceId = params.get(SEARCH_PARAMS.SELECTED_PRICE_ID);
      if (selectedPriceId) {
        const selected = priceListToShow.find((item) => item.price._id === selectedPriceId);
        if (selected) {
          return selected;
        }
      }
      priceListToShow.forEach((item) => {
        const isNotSoldOut = !item.options.soldOut;
        if (isNotSoldOut && !notSoldOut) {
          notSoldOut = item;
        }
        if (isNotSoldOut && item.defaultSelected) {
          defaultSelected = item;
        }
      });

      if (defaultSelected) {
        return defaultSelected;
      }
      if (notSoldOut) {
        return notSoldOut;
      }
    }
    return null;
  }, [priceListToShow, params]);

  useEffect(() => {
    if (initialSelectedPaywallList) {
      setSelectedPaywallList(initialSelectedPaywallList);
    }
  }, [initialSelectedPaywallList, setSelectedPaywallList]);

  const handleSelectPaywallList = useCallback(
    (priceId: string) => {
      const paywallList = priceListToShow.find((el) => el.price._id === priceId);
      if (paywallList) {
        setSelectedPaywallList(paywallList);
      }
    },
    [priceListToShow]
  );

  const actionsProcessor = useMemo(
    () =>
      ActionsProcessorFactory(
        [
          new PaywallCtaClickActionProcessor(togglePaymentDrawer),
          new PaywallCtaScrollActionProcessor(handleScroll),
          new ActiveStateToggleActionProcessor(handleSelectPaywallList),
        ],
        {
          logger,
        }
      ),
    [logger, togglePaymentDrawer, handleScroll, handleSelectPaywallList]
  );

  const handleStat = useCallback(
    (e: StatCallbackType) => {
      eventEmitter.process(e);
      actionsProcessor.process(e);
    },
    [eventEmitter, actionsProcessor]
  );

  const divkitNode = useMemo(() => {
    if (paywallLayout?.mainLayout) {
      return (
        <DivKit
          extensions={extensions.providers}
          globalVariablesController={globalVariables.controller}
          id={_id}
          json={paywallLayout.mainLayout}
          onStat={handleStat}
          customComponents={components.components}
          onError={(e) => {
            if (e.error.level === 'warn') {
              logger.logWarning({ message: e.error.message }, e.error.message);
            } else {
              logger.logError(e as unknown as Error);
            }
          }}
        />
      );
    }
    return null;
  }, [
    paywallLayout?.mainLayout,
    _id,
    extensions.providers,
    globalVariables.controller,
    handleStat,
    components.components,
    logger,
  ]);

  const priceListDivkitNode = useMemo(() => {
    if (container?.length) {
      if (paywallLayout?.priceListItemLayout) {
        const divkitNodes = priceListToShow.map((el) => {
          const filteredVariables =
            paywallLayout.priceListItemLayout!.card.variables?.filter(
              (el) => el.name !== 'priceListItem' && el.name !== 'stateValue'
            ) ?? [];

          // todo: A forced solution since there's no way to replace localization key data after the page is rendered.
          const priceListItemLayout = JSON.parse(
            JSON.stringify(paywallLayout.priceListItemLayout)
              .replaceAll('TITLE_KEY_REPLACE', el.translations.priceTitle)
              .replaceAll('OFFER_KEY_REPLACE', el.translations.offerTitle)
              .replaceAll('DESCRIPTION_KEY_REPLACE', el.translations.priceDescription)
          );

          const json = {
            ...priceListItemLayout,
            card: {
              ...priceListItemLayout!.card,
              variables: [
                ...filteredVariables,
                {
                  name: 'priceListItem',
                  type: 'dict',
                  value: el,
                },
                {
                  name: 'stateValue',
                  type: 'string',
                  value: el === selectedPaywallList ? 'selected' : 'normal',
                },
              ],
            },
          };

          return (
            <DivKit
              key={el.price._id}
              json={json as DivJson}
              id={el.price._id}
              globalVariablesController={globalVariables.controller}
              extensions={extensions.providers}
              onStat={handleStat}
              onError={(e) => {
                if (e.error.level === 'warn') {
                  logger.logWarning({ message: e.error.message }, e.error.message);
                } else {
                  logger.logError(e as unknown as Error);
                }
              }}
            />
          );
        });

        return container.map((el, i) =>
          createPortal(<div ref={i === 0 ? priceListRef : undefined}>{divkitNodes}</div>, el)
        );
      }
    }
    return null;
  }, [
    container,
    paywallLayout?.priceListItemLayout,
    globalVariables.controller,
    selectedPaywallList,
    priceListToShow,
    setSelectedPaywallList,
    extensions.providers,
  ]);

  const closePaymentDrawer = () => {
    if (openPaymentDrawer) {
      togglePaymentDrawer();
    }
    if (specialOfferPaywall && !isPayed && specialOfferInfoDrawerEnabled) {
      toggleSpecialOfferInfoDrawerEnabled();
      toggleSpecialOfferInfoDrawer();
    }
  };

  const closeDiscountInfoDrawer = () => {
    searchParams.set('popupVariant', specialOfferPaywall);
    setSearchParams(searchParams);
    toggleSpecialOfferInfoDrawer();

    analytics.logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.DISCOUNT_PAYWALL,
      properties: {},
    });
  };

  // ToDo: проверить на замыкание
  const paymentDrawer = useMemo(() => {
    if (selectedPaywallList) {
      const Drawer = specialOfferPaywall
        ? withPreventBackNavigation(OfferPaymentDrawer, closePaymentDrawer, specialOfferInfoDrawerEnabled)
        : OfferPaymentDrawer;
      return <Drawer open={openPaymentDrawer} onClose={closePaymentDrawer} />;
    }
    return null;
  }, [selectedPaywallList, openPaymentDrawer, togglePaymentDrawer, specialOfferInfoDrawerEnabled, specialOfferPaywall]);

  const discountInfoDrawer = useMemo(() => {
    if (selectedPaywallList) {
      return <SpecialOfferInfoDrawer onClose={closeDiscountInfoDrawer} open={openSpecialOfferInfoDrawer} />;
    }
    return null;
  }, [openSpecialOfferInfoDrawer, specialOfferPaywall]);

  return (
    <>
      {divkitNode}
      {priceListDivkitNode}
      {paymentDrawer}
      {discountInfoDrawer}
    </>
  );
};

export default D_OFFER;

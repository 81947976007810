import React, { useEffect, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PAYWALL_TYPE, PRODUCT_NAMES } from '@organicapps/organictypes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AlertBar from '../../components/molecules/AlertBar/AlertBar';
import FormInput from '../../components/atoms/FormInput/FormInput';
import FORM, { FORM_NAMES } from '../../constants/forms';
import { validateInput } from '../../helpers/formsValidation';
import { usePaywallsStore, useUserStore } from '../../store';
import LoadingButton from '../../components/atoms/LoadingButton/LoadingButton';
import { PAGES } from '../../constants/pages';
import { IPagesParams } from '../Offer';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../hooks/useAnalytics';
import ErrorMessage from '../../components/molecules/ErrorMessage/ErrorMessage';
import withPaywallTypeGuard from '../../HOCs/withPaywallTypeGuard';
import withIsPayedRouteGuard from '../../HOCs/withIsPayedRouteGuard';
import WithFullWidth from '../../HOCs/withFullWidth';
import ApiClients from '../../api';
import { useRouterWrapper } from '../../hooks/useRouterWrapper';
import { useLogger } from '../../hooks/useLogger';
import Stepper from '../../components/molecules/Stepper/Stepper';
import { STEPPER_LIST } from '../Upsells/fastingDancePrograms';
import { APPLE_SIGN_IN_LINKS } from '../../constants/links';
import { PLATFORM } from '../../constants/platforms';
import VendorButton from '../../components/atoms/VendorButton/VendorButton';
import { useFingerprint } from '../../hooks/useFingerprint';

const CONTENT = {
  [PRODUCT_NAMES.DANCE]: 'register.title',
  [PRODUCT_NAMES.FIT]: 'register.title',
  [PRODUCT_NAMES.WALK]: 'register.title',
  [PRODUCT_NAMES.MAN]: 'register.title',
  [PRODUCT_NAMES.VEGAN]: 'register.title',
  [PRODUCT_NAMES.FASTING]: 'register.title',
  [PRODUCT_NAMES.KETO]: 'register.title',
};

interface IFormInputState {
  value: string;
  error: string;
  touched: boolean;
  complete: boolean;
}

const initialFormInputState: IFormInputState = {
  value: '',
  error: '',
  touched: false,
  complete: false,
};

class AppleError extends Error {
  error?: string;
}

const Register = () => {
  const { productName } = useParams<keyof IPagesParams>() as IPagesParams;
  const go = useRouterWrapper();
  const userClient = ApiClients.getUserClient();

  const { setEmailToStore, userEmail, firstName, lastName, isRegistered, setIsRegistered, appUserToken } = useUserStore(
    (state) => ({
      setEmailToStore: state.setEmail,
      userEmail: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      isRegistered: state.complete,
      setIsRegistered: state.setIsRegistered,
      appUserToken: state.appUserToken,
    })
  );

  const { upsellsList } = usePaywallsStore((state) => ({
    upsellsList: state.upsellsList,
  }));

  const [name, setName] = useState<IFormInputState>(() => {
    return firstName || lastName
      ? {
          value: `${firstName || ''} ${lastName || ''}`.trim(),
          touched: true,
          error: '',
          complete: true,
        }
      : initialFormInputState;
  });
  const [email, setEmail] = useState<IFormInputState>(() => {
    return userEmail
      ? {
          value: userEmail,
          touched: true,
          error: '',
          complete: true,
        }
      : initialFormInputState;
  });
  const [password, setPassword] = useState<IFormInputState>(initialFormInputState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isConfirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const { t } = useTranslation();

  const { logEvent } = useAnalytics();
  const logger = useLogger();
  const fingerprint = useFingerprint();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateInput(e.target.name as FORM_NAMES, e.target.value);
    const touched = true;
    setName({
      value: e.target.value,
      touched,
      error,
      complete: !error && touched,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateInput(e.target.name as FORM_NAMES, e.target.value);
    const touched = true;
    setEmail({
      value: e.target.value.trim(),
      touched: true,
      error,
      complete: !error && touched,
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateInput(e.target.name as FORM_NAMES, e.target.value);
    const touched = true;
    setPassword({
      value: e.target.value.trim(),
      touched: true,
      error,
      complete: !error && touched,
    });
  };

  const handleSubmit = async () => {
    const lowerCasedEmail = email.value.toLowerCase();
    try {
      setIsLoading(true);
      setError('');
      const userClient = ApiClients.getUserClient();
      const responseData = await userClient.onRegister({
        name: name.value,
        email: lowerCasedEmail,
        password: password.value,
        appUserToken,
      });
      if ((responseData?.message && responseData?.message !== 'ok') || responseData?.error) {
        throw new Error(responseData?.message);
      }
      logEvent({
        type: EVENT_TYPES.ACTION,
        name: EVENTS.USER_REGISTERED,
        properties: {
          email: lowerCasedEmail,
        },
        userProperties: { Email: lowerCasedEmail },
      });
      if (userEmail !== lowerCasedEmail) {
        setEmailToStore(lowerCasedEmail);
      }
      setIsRegistered(true);
    } catch (e) {
      setError('Something went wrong with registration. Please try again.');
      logEvent({
        type: EVENT_TYPES.ACTION,
        name: EVENTS.USER_REGISTRATION_ERROR,
        properties: {
          email: lowerCasedEmail,
        },
      });
      logger.logError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    const signIn = window?.AppleID?.auth?.signIn;
    if (signIn) {
      try {
        setIsLoading(true);
        setError('');

        const appleResponse = await signIn();
        const code = appleResponse?.authorization?.code;
        const state = appleResponse?.authorization?.state;
        const firstName = appleResponse?.user?.name?.firstName;
        const lastName = appleResponse?.user?.name?.lastName;
        const fullName = `${firstName || ''}${lastName ? ` ${lastName}` : ''}`;

        if (state !== appUserToken) {
          throw new Error('Possible Cross-Site Request Forgery Attacks', { cause: appleResponse });
        }
        if (!code) {
          throw new Error('Auth code was not provided', { cause: appleResponse });
        }

        const responseData = await userClient.onAppleConnect(
          {
            authorizationCode: code,
            clientId: APPLE_SIGN_IN_LINKS[productName],
            redirectUrl: window.location.origin,
            fullName,
          },
          appUserToken
        );
        if ((responseData?.message && responseData?.message !== 'ok') || responseData?.error) {
          throw new Error(responseData?.message);
        }

        logEvent({
          type: EVENT_TYPES.ACTION,
          name: EVENTS.APPLE_CONNECTED,
          properties: {},
        });

        setIsRegistered(true);
      } catch (e: unknown) {
        const errorToShow =
          (e as AppleError)?.error ??
          (e as Error)?.message ??
          'Something went wrong with Apple Sign In. Please try again.';

        logEvent({
          type: EVENT_TYPES.ACTION,
          name: EVENTS.APPLE_CONNECT_ERROR,
          properties: {},
        });

        setError(errorToShow);
        logger.logError(e as Error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    setConfirmDisabled(!name.complete || !email.complete || !password.complete);
  }, [name.complete, email.complete, password.complete]);

  useEffect(() => {
    if (isRegistered) {
      fingerprint
        .createFingerprint()
        .then((fingerprint) => {
          userClient.sendUserFingerprint(appUserToken, fingerprint).catch((e) => logger.logError(e as Error));
        })
        .catch((e) => logger.logError(e as Error));
      go({ routeChangedPart: { page: PAGES.DOWNLOAD } });
    }
  }, [isRegistered, appUserToken, userClient, logger]);

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.REGISTER,
      properties: {},
    });
  }, []);

  useEffect(() => {
    const init = window?.AppleID?.auth?.init;
    if (init) {
      try {
        init({
          clientId: APPLE_SIGN_IN_LINKS[productName],
          usePopup: true,
          scope: 'name email',
          nonce: appUserToken,
          state: appUserToken,
          redirectURI: window.location.origin,
        });
      } catch (e) {
        logger.logError(e as Error);
      }
    }
  }, [window?.AppleID]);

  const FullWidthAlertBar = WithFullWidth(AlertBar);

  return (
    <>
      {upsellsList?.length ? <Stepper sx={{ '&': { marginBottom: 5 } }} activeStep={1} steps={STEPPER_LIST} /> : null}
      <Typography variant='h2' align='center'>
        {t('register.one_more_step')}
      </Typography>
      <FullWidthAlertBar mt={2}>{t(CONTENT[productName])}</FullWidthAlertBar>
      <FormInput
        complete={name.complete}
        onBlur={(_: React.FocusEvent<HTMLInputElement>) => setName((prev) => ({ ...prev, value: prev.value.trim() }))}
        name={FORM.name.name}
        label={t(FORM.name.label)}
        value={name.value}
        onChange={handleNameChange}
        placeholder={t(FORM.name.placeholder)}
        sx={{ mt: 5 }}
        error={!!name.error}
        helperText={name.error ? t(name.error) : ''}
      />
      <FormInput
        complete={email.complete}
        name={FORM.email.name}
        label={t(FORM.email.label)}
        placeholder={t(FORM.email.placeholder)}
        sx={{ mt: 5 }}
        value={email.value}
        onChange={handleEmailChange}
        error={!!email.error}
        helperText={email.error ? t(email.error) : ''}
        autoComplete='username'
      />
      <FormInput
        type={showPassword ? 'text' : 'password'}
        autoCapitalize='off'
        complete={password.complete}
        name={FORM.password.name}
        label={t(FORM.password.label)}
        placeholder={t(FORM.password.placeholder)}
        value={password.value}
        onChange={handlePasswordChange}
        sx={{ mt: 5 }}
        error={!!password.error}
        helperText={password.error ? t(password.error) : ''}
        customIcon={
          <IconButton sx={{ padding: 0 }} onClick={handleClickShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        }
        autoComplete='new-password'
      />
      <Typography variant='body2' color='text.secondary' mt={2}>
        {t('email.note')}
      </Typography>
      {error && <ErrorMessage mt={4}>{error}</ErrorMessage>}
      <LoadingButton onClick={handleSubmit} loading={isLoading} sx={{ mt: 9 }} disabled={isConfirmDisabled}>
        {t('button.continue')}
      </LoadingButton>
      <VendorButton vendor={PLATFORM.APPLE} onClick={handleAppleSignIn} sx={{ mt: 5, '& svg': { height: 22 } }}>
        {t('button.sign_in.apple')}
      </VendorButton>
    </>
  );
};

export default withPaywallTypeGuard(withIsPayedRouteGuard(Register), PAYWALL_TYPE.OFFER);

import React from 'react';

const Fit = () => {
  return (
    <>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9319 3.94727C18.8132 3.94727 20.3334 5.42947 20.3334 7.25373C20.3334 9.07798 18.8132 10.5602 16.9319 10.5602H10.566C8.1147 10.5412 6.23344 11.9284 5.15029 13.8286C5.05527 14.0187 4.77023 13.9807 4.71322 13.7906C4.44719 13.0115 4.29517 12.1374 4.29517 11.1493C4.29517 7.53877 6.76551 3.94727 10.7371 3.94727H16.9319ZM16.1528 15.6149C16.1528 17.4391 14.6326 18.9214 12.7513 18.9214C11.2545 18.9214 9.80995 18.9015 8.52678 19.8148C7.20979 20.7522 6.57548 22.2243 6.57548 23.805C6.57548 23.9951 6.34745 24.0711 6.23344 23.919C5.37832 22.7979 4.86525 21.3157 4.86525 19.5104C4.86525 16.8074 6.31155 14.1002 8.66675 12.92C9.95955 12.2722 11.3106 12.3084 12.7133 12.3084C14.5266 12.3084 16.1528 13.7517 16.1528 15.6149Z'
        fill='#FF575C'
      />
      <path
        d='M31.7274 6.78117C28.6923 6.78117 25.6919 9.31329 25.6919 12.9727C25.6919 16.6322 28.6923 19.1643 31.7274 19.1643C34.7798 19.1643 37.7802 16.6322 37.7802 12.9727C37.7802 9.31329 34.7798 6.78117 31.7274 6.78117ZM31.7274 9.00111C33.6698 9.00111 35.3348 10.5967 35.3348 12.9727C35.3348 15.3488 33.6525 16.9444 31.7274 16.9444C29.8196 16.9444 28.1373 15.3488 28.1373 12.9727C28.1373 10.5967 29.8196 9.00111 31.7274 9.00111Z'
        fill='black'
      />
      <path
        d='M44.4899 9.93765C42.929 9.93765 41.8537 11.3598 41.7843 11.9842V11.117C41.7843 10.4926 41.264 9.97234 40.657 9.97234C40.0327 9.97234 39.5124 10.4926 39.5124 11.117V18.0023C39.5124 18.6093 40.0327 19.1296 40.657 19.1296C41.264 19.1296 41.7843 18.6093 41.7843 18.0023V13.8226C41.8711 13.1115 42.7382 12.0535 44.1083 12.0535C45.0796 12.0535 45.5652 11.62 45.5652 10.9783C45.5652 10.4233 45.201 9.93765 44.4899 9.93765Z'
        fill='black'
      />
      <path
        d='M55.1592 11.0997C55.1592 10.4926 54.6042 9.97234 53.9972 9.97234C53.3728 9.97234 52.8525 10.4926 52.8525 11.0997V11.3945C52.5056 10.5794 51.5171 9.92031 50.2857 9.92031C48.3953 9.92031 46.0886 11.4465 46.0886 14.5336C46.0886 17.6901 48.3779 19.1643 50.2857 19.1643C51.7946 19.1643 52.7138 18.2278 52.8525 17.6901V18.8695C52.8525 19.8927 52.0721 20.6385 50.6672 20.6385C49.8868 20.6385 49.2971 20.4304 48.9503 20.3437C48.7248 20.2743 48.586 20.2569 48.482 20.2569C47.9964 20.2569 47.5801 20.6385 47.5801 21.1588C47.5801 21.575 47.8403 21.9219 48.3259 22.13C48.7074 22.3034 49.5746 22.5983 50.6499 22.5983C52.4883 22.5983 55.1245 21.6964 55.1245 18.8695V18.2798C55.1418 18.1931 55.1592 18.1064 55.1592 18.0196V11.0997ZM50.7019 17.2045C49.5573 17.2045 48.3606 16.32 48.3606 14.5336C48.3606 12.7646 49.5573 11.8628 50.7019 11.8628C51.6732 11.8628 52.9566 12.5738 52.9566 14.5336C52.9566 16.4934 51.6732 17.2045 50.7019 17.2045Z'
        fill='black'
      />
      <path
        d='M64.6125 9.97234C63.9881 9.97234 63.4678 10.4926 63.4678 11.0997V11.3945C63.121 10.5794 62.1324 9.92031 60.901 9.92031C59.0106 9.92031 56.7039 11.4465 56.7039 14.5336C56.7039 17.6901 58.9933 19.1643 60.901 19.1643C62.4099 19.1643 63.3291 18.2278 63.4678 17.6901V18.0196C63.4678 18.644 63.9881 19.147 64.6125 19.147C65.2195 19.147 65.7745 18.644 65.7745 18.0196V11.0997C65.7745 10.4926 65.2195 9.97234 64.6125 9.97234ZM61.3173 17.2045C60.1726 17.2045 58.9759 16.32 58.9759 14.5336C58.9759 12.7646 60.1726 11.8628 61.3173 11.8628C62.2885 11.8628 63.5719 12.5738 63.5719 14.5336C63.5719 16.4934 62.2885 17.2045 61.3173 17.2045Z'
        fill='black'
      />
      <path
        d='M72.9208 9.93765C71.0477 9.93765 70.0244 11.3598 69.9551 11.9842V11.117C69.9551 10.4926 69.4348 9.97234 68.8277 9.97234C68.2034 9.97234 67.6831 10.4926 67.6831 11.117V18.0023C67.6831 18.6267 68.2034 19.1296 68.8277 19.1296C69.4348 19.1296 69.9551 18.6267 69.9551 18.0023V13.8226C70.0244 13.3369 70.5447 11.8801 72.1056 11.8801C73.1289 11.8801 73.944 12.5218 73.944 14.0827V18.0023C73.944 18.6267 74.4643 19.1296 75.0713 19.1296C75.6957 19.1296 76.216 18.6267 76.216 18.0023V14.0827C76.216 11.0823 74.6724 9.93765 72.9208 9.93765Z'
        fill='black'
      />
      <path
        d='M79.2911 8.74096C79.8981 8.74096 80.4357 8.22066 80.4357 7.61365C80.4357 7.00663 79.8981 6.48633 79.2911 6.48633C78.6667 6.48633 78.1291 7.00663 78.1291 7.61365C78.1291 8.22066 78.6667 8.74096 79.2911 8.74096ZM79.2911 9.97234C78.6667 9.97234 78.1464 10.4926 78.1464 11.117V17.985C78.1464 18.6267 78.6667 19.147 79.2911 19.147C79.8981 19.147 80.4184 18.6267 80.4184 17.985V11.117C80.4184 10.4926 79.8981 9.97234 79.2911 9.97234Z'
        fill='black'
      />
      <path
        d='M89.0541 16.2506C88.6379 16.2506 88.3084 16.4934 88.1696 16.6148C87.7707 16.9964 87.1811 17.1525 86.7128 17.1525C85.3427 17.1525 84.25 16.1986 84.25 14.5336C84.25 12.8513 85.3427 11.8974 86.7128 11.8974C87.1811 11.8974 87.7707 12.0535 88.1696 12.4351C88.3084 12.5565 88.6379 12.7993 89.0541 12.7993C89.5224 12.7993 89.9733 12.4698 89.9733 11.9321C89.9733 11.3771 89.5571 11.0997 89.3316 10.8742C88.6032 10.2325 87.7534 9.92031 86.6261 9.92031C84.5275 9.92031 81.9954 11.4292 81.9954 14.5336C81.9954 17.6207 84.5275 19.1296 86.6261 19.1296C87.7534 19.1296 88.6032 18.8001 89.3316 18.1584C89.5571 17.9503 89.9733 17.6728 89.9733 17.1178C89.9733 16.5801 89.5224 16.2506 89.0541 16.2506Z'
        fill='black'
      />
      <path
        d='M102.641 7.90848C102.641 7.3535 102.155 6.90257 101.6 6.90257H95.842C95.0962 6.90257 94.5586 7.44021 94.5586 8.18597V17.9676C94.5586 18.6093 95.0962 19.1643 95.7379 19.1643C96.3796 19.1643 96.8999 18.6093 96.8999 17.9676V14.2388H100.75C101.305 14.2388 101.773 13.8052 101.773 13.2502C101.773 12.6779 101.305 12.2443 100.75 12.2443H96.8999V8.94908H101.6C102.155 8.94908 102.641 8.48081 102.641 7.90848Z'
        fill='black'
      />
      <path
        d='M105.269 8.74096C105.876 8.74096 106.414 8.22066 106.414 7.61364C106.414 7.00663 105.876 6.48633 105.269 6.48633C104.645 6.48633 104.107 7.00663 104.107 7.61364C104.107 8.22066 104.645 8.74096 105.269 8.74096ZM105.269 9.97234C104.645 9.97234 104.124 10.4926 104.124 11.117V17.985C104.124 18.6267 104.645 19.147 105.269 19.147C105.876 19.147 106.396 18.6267 106.396 17.985V11.117C106.396 10.4926 105.876 9.97234 105.269 9.97234Z'
        fill='black'
      />
      <path
        d='M112.535 17.1004C112.344 17.1004 112.066 17.1698 111.876 17.1698C111.494 17.1698 111.321 16.9097 111.321 16.4067V11.8107H112.465C113.038 11.8107 113.384 11.4465 113.384 10.9262C113.384 10.3886 113.038 10.0244 112.465 10.0244H111.321V8.84502C111.321 8.22066 110.818 7.70036 110.193 7.70036C109.569 7.70036 109.049 8.22066 109.049 8.84502V10.0244H108.546C107.991 10.0244 107.626 10.3886 107.626 10.9262C107.626 11.4465 107.991 11.8107 108.546 11.8107H109.049V16.3373C109.049 18.7481 110.245 19.1296 111.425 19.1296C112.604 19.1296 113.384 18.7134 113.384 17.9156C113.384 17.3779 113.055 17.0658 112.535 17.1004Z'
        fill='black'
      />
    </>
  );
};

export default Fit;

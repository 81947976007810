import ExtensionsProviderFactory from './factory';
import type { IExtensionBuilder, IExtensionsProviderOptions } from './types';

export { EXTENSION_NAMES } from './builders';

export type { IExtensionsProviderOptions, ExtensionsProvider, IExtensionBuilder } from './types';

export { default as IntercomExtensionBuilder } from './builders/intercom';
export { default as LottieExtensionBuilder } from './builders/lottie';
export { default as LocalizationExtensionBuilder } from './builders/localization';
export { default as StickyExtensionBuilder } from './builders/sticky';
export { default as CountUpExtensionBuilder } from './builders/countUp';
export { default as CountDownExtensionBuilder } from './builders/countDown';

export default function factory(
  providers: IExtensionBuilder[],
  opts?: IExtensionsProviderOptions
): InstanceType<typeof ExtensionsProviderFactory> {
  return new ExtensionsProviderFactory(providers, opts);
}
